import { ResourceHeaders } from '..'
import {
  AlertsHeader, ChipCellHeader,
  IdHeader, TextHeader,
} from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    options: {
      expandOff: true,
      action: {
        edit: Helpers.linkedModel,
      },
    },
    width: 80,
  },
  {
    ...TextHeader, text: 'Nombre', value: 'name', width: 200,
  },
  {
    ...AlertsHeader, text: 'Alertas ', value: 'alerts', sortable: true,
  },
  {
    ...TextHeader, text: 'Fecha de ingreso', value: 'admissionDate', width: 200,
  },
  {
    ...TextHeader, text: 'Telefono', value: 'phone', width: 200,
  },
  {
    ...TextHeader, text: 'Correo', value: 'person.user.email', width: 200,
  },
  {
    ...ChipCellHeader, text: 'Roles', value: 'person.user.roles', width: 200,
  },
]

export default function (): ResourceHeaders {
  return headers
}
