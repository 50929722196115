import { ResourceHeaders } from '..'

import {
  AlertsHeader,
  AppraisalStatusHeader,
  AppraiserHeader,
  BusinessHeader,
  ChannelHeader,
  DateHeader,
  FIHeader,
  FinancialHeader,
  IdHeader,
  LeadActivityHistoryHeader,
  TextHeader,
  VehicleHeader,
  VehicleStatusHeader,
} from '../../headers'
import { Helpers } from '../../helpers'
import { Deal, Lead } from '@/entities/crm'
import { Process } from '@/entities/settings'
import { CellType, TableCellHeader } from '@/components/dataTables/cell'
import { extendsObj } from '@/utils/data'

export const headers: ResourceHeaders = [
  { ...IdHeader, text: 'Nº', value: '@' },
  { ...AlertsHeader, text: 'Alertas ', value: 'alerts' },
  { ...DateHeader, text: 'Creación', value: 'created' },
  { ...LeadActivityHistoryHeader, text: 'Vencimiento tarea', value: '@', sortable: true },
  { ...BusinessHeader, text: 'Negocio ', value: 'pipeline' },
  { ...ChannelHeader, text: 'Canal', value: 'channel' },
  { ...TextHeader, value: 'client.shortName', text: 'Cliente', width: 200 },
  {
    align: 'center',
    filterable: true,
    type: CellType.linked,
    text: 'Ejecutivo',
    value: '@',
    options: {
      disabledActionMissingProperty: {
        key: 'executive',
      },
      path: 'executive.person.name',
      action: {
        color: 'primary',
        always: true,
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: Lead, process: Process, system: string) => Helpers.contact(item, 'executive', process, system),
      },
      disabledMissingProperty: ['phone'],
    },
    width: 250,
  },
  extendsObj<TableCellHeader>({
    text: 'Stock',
    value: 'sale',
    options: {
      path: 'auto.name',
      trigger: ({ stock }: Deal) => Helpers.linkedModel({ constructor: { name: 'SupervisorStockPublish' }, ...stock }),
    },
  }, VehicleHeader),
  { ...VehicleStatusHeader, text: 'Estado de Stock', value: 'sale.stock' },
  { ...AppraiserHeader, text: 'Tasación', value: 'purchase' },
  { ...AppraisalStatusHeader, text: 'Estado tasación', value: 'purchase.appraisal' },
  { ...FinancialHeader, text: 'Financiamiento', value: 'sale.order.financing.evaluation' },
  { ...FIHeader, text: 'F & I', value: '@' },
]

export default function (): ResourceHeaders {
  return headers
}
