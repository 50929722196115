import { ResourceHeaders } from '..'
import {
  AppraisalHeader,
  AppraisalStatusHeader,
  BestOfferHeader,
  BusinessHeader,
  IdHeader,
  IndicatorHeader,
  ListActionHeader,
  PersonHeader,
  PriceHeader,
  VehicleHeader,
} from '../../headers'
import { CellType } from '@/components/dataTables/cell'
import { Helpers } from '@/views/datatables/resources/helpers'
import { Lead } from '@/entities/crm'
import { Process } from '@/entities/settings'

export const headers: ResourceHeaders = [
  { ...IdHeader, text: 'Nº', value: '@' },
  { ...VehicleHeader, text: 'Vehiculo', value: 'deal.auto', sortable: true },
  { ...BusinessHeader, text: 'Negocio ', value: 'pipeline' },
  {
    ...PersonHeader,
    text: 'Cliente',
    value: '@',
    options: {
      path: 'client.name',
      trigger: Helpers.linkedModel,
      action: {
        always: true,
        color: 'green',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: Lead, process: Process, system: string) => Helpers.contact(item, 'client', process, system),
      },
      disabledMissingProperty: ['phone'],
    },
  },
  {
    ...AppraisalHeader,
    text: 'Tasador',
    value: 'appraiser',
    options: {
      action: {
        always: true,
        color: 'primary',
        icon: 'mdi-whatsapp',
        only: true,
        trigger: (item: Lead, process: Process, system: string) => Helpers.contact(item, 'appraiser', process, system),
      },
      disabledMissingProperty: ['phone'],
    },
  },
  { ...AppraisalStatusHeader, text: 'Estado tasación', value: 'appraisalStatus' },
  {
    ...IndicatorHeader,
    text: 'Indicadores',
    value: 'indicator',
    width: 140,
    options: {
      component: CellType.alert,
      cols: 6,
      row: {
        pt: 1,
      },
      col: {
        pt: 1,
      },
    },
  },
  /* {
    ...MarketPricesHeader,
    text: 'Precios de mercado',
    value: 'market.overview',
    width: 175,
    options: {
      component: CellType.price,
      trigger: Helpers.addLinkedModel('appraisal report'),
      row: {
        py: 2,
      },
    },
  }, */
  { ...PriceHeader, text: 'Diferencia acuerdo', value: 'agreement', width: 170 },
  { ...BestOfferHeader, text: 'Mejor oferta', value: 'bestOffer', sortable: false },
  {
    ...ListActionHeader,
    text: 'Agendar',
    value: 'appraisalActions',
    sortable: false,
    options: {
      cols: 12,
      component: CellType.action,
    },
  },
]

export default function (): ResourceHeaders {
  return headers
}
