import { ResourceHeaders } from '..'
import {
  AddressHeader,
  BusinessHeader,
  DateHeader,
  IdHeader,
  InspectionProgressHeader,
  InspectionQualificationHeader,
  InspectionStatusHeader,
  InspectionSummary,
  VehicleHeader,
  WhatsappHeader,
} from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'
import { Person } from '@/entities/persons'

export const headers: ResourceHeaders = [
  { ...IdHeader, text: 'Nº', value: '@' },
  { ...VehicleHeader, text: 'Vehiculo', value: 'appraisal.deal.auto' },
  { ...DateHeader, text: 'Fecha Agendada', value: 'scheduled' },
  { ...DateHeader, text: 'Fecha Inicio', value: 'dateFormatted' },
  { ...AddressHeader, text: 'Ubicación', value: 'address' },
  { ...BusinessHeader, text: 'Negocio ', value: 'appraisal.deal.lead.pipeline' },
  { ...InspectionStatusHeader, text: 'Estado', value: '@' },
  {
    ...WhatsappHeader,
    text: 'Ejecutivo',
    value: 'executive',
    options: {
      action: {
        color: 'green',
        icon: 'mdi-whatsapp',
        only: true,
        trigger: (item: Person) => Helpers.contact(item, 'Supervisor'),
      },
      disabledMissingProperty: ['phone'],
    },
  },
  {
    ...WhatsappHeader,
    text: 'Inspector',
    value: 'inspector',
    options: {
      action: {
        color: 'primary',
        icon: 'mdi-whatsapp',
        only: true,
        trigger: (item: Person) => Helpers.contact(item, 'Supervisor'),
      },
    },
  },
  {
    ...InspectionSummary,
    text: 'Reporte',
    value: '@',
    options: {
      tooltip: 'Reporte',
      disabled: true,
      action: {
        close: true,
        color: 'purple',
        icon: 'mdi-clipboard-text-search-outline',
        only: true,
      },
    },
  },
  { ...InspectionProgressHeader, text: 'Progreso inspector', value: '@' },
  { ...InspectionQualificationHeader, text: 'Calificación inspector', value: 'qualificationInspector' },
  { ...InspectionProgressHeader, text: 'Progreso supervisor', value: '@', options: { supervisor: true } },
  { ...InspectionQualificationHeader, text: 'Calificación supervisor', value: 'qualification' },
]

export default function (): ResourceHeaders {
  return headers
}
