import { ResourceHeaders } from '..'

import {
  AlertsHeader,
  AppraisalStatusHeader,
  AppraiserHeader,
  BusinessHeader,
  ChannelHeader,
  DateHeader,
  FinancialHeader,
  IdHeader,
  LeadActivityHistoryHeader,
  PersonHeader,
  TextHeader,
  VehicleHeader,
  VehicleStatusHeader,
} from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'
import { Person } from '@/entities/persons'

export const headers: ResourceHeaders = [
  { ...IdHeader, text: 'Nº', value: '@' },
  { ...AlertsHeader, text: 'Alertas ', value: 'alerts' },
  { ...DateHeader, text: 'Creación', value: 'created' },
  { ...LeadActivityHistoryHeader, text: 'Vencimiento tarea', value: '@', sortable: true },
  { ...BusinessHeader, text: 'Negocio ', value: 'pipeline' },
  { ...ChannelHeader, text: 'Canal', value: 'channel' },
  { ...TextHeader, value: 'client.shortName', text: 'Cliente', width: 200 },
  {
    ...PersonHeader,
    text: 'Ejecutivo',
    value: 'executive',
    options: {
      action: {
        color: 'primary',
        always: true,
        icon: 'mdi-whatsapp',
        trigger: (item: Person) => Helpers.contact(item, 'Ejecutivo'),
      },
    },
  },
  { ...VehicleHeader, text: 'Stock', value: 'sale.auto' },
  { ...VehicleStatusHeader, text: 'Estado de Stock', value: 'sale.stock' },
  { ...AppraiserHeader, text: 'Tasación', value: 'purchase' },
  { ...AppraisalStatusHeader, text: 'Estado tasación', value: 'purchase.appraisal' },
  { ...FinancialHeader, text: 'Financiamiento', value: 'sale.order.financing.evaluation' },
]

export default function (): ResourceHeaders {
  return headers
}
