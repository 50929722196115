import {
  CellType, IconCellDefinition,
  LinkedCellDefinition,
  StatusCellDefinition,
} from '@/components/dataTables/cell'
import { BusinessStatusIcons } from '@/models/lead/interfaces'

export const BusinessHeader: StatusCellDefinition = {
  align: 'center',
  width: 180,
  filterable: true,
  type: CellType.status,
  options: {
    set: BusinessStatusIcons,
  },
}

export const ChannelHeader: IconCellDefinition = {
  align: 'center',
  sortable: false,
  width: 95,
  filterable: false,
  type: CellType.icon,
}

export const ViewLeadHeader: LinkedCellDefinition = {
  align: 'center',
  sortable: false,
  width: 85,
  filterable: false,
  type: CellType.linked,
}
